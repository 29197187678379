import '../css/HrStyle.css';
import '../../../Media.css';
import { useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function LicenseModulesList() {
	const inputBox = useRef(null);
	const table = useRef(null);
	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='UserList'>
				<div className='row g-0 px-2 justify-content-between align-items-end'>
					<div className='col-md-6 col-sm-12 col-12'>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DatePicker']}>
								<DatePicker size="sm" label="AUP Validity" slotProps={{ textField: { size: 'small', fullWidth: true } }} />
							</DemoContainer>
						</LocalizationProvider>
					</div>
					<div className='col-md-auto col-sm-12 col-12 d-flex align-items-center'>
						<input className="form-control searchBoxAdmi shadow-sm mx-0 w-100" type="text" ref={inputBox}
							onKeyUp={myFunction} placeholder="Search Module" />
					</div>
				</div>
				<div className='userIDData'>
					<Table hover bordered responsive align='' ref={table}>
						<thead className='bg-info'>
							<tr>
								<th>Module</th>
								<th>Current Usage</th>
								<th>Allowed Limit</th>
							</tr>
						</thead>
						<tbody >
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>Platform users</td>
								<td>615</td>
								<td>650</td>
							</tr>
							<tr>
								<td>ACM Users</td>
								<td>0</td>
								<td>0</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

		</>
	);
}
export default LicenseModulesList;
