
import $ from 'jquery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
function EmplShiftSchedule() {
	function getDate() {
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const date = today.getDate();
		return `${month}/${date}/${year}`;
	  }
	  const [currentDate, setCurrentDate] = useState(getDate());
	return (
		<>

			<div className='main_wrapper shadow-sm'>
				<div className='header_month'>Shift Schedule</div>
				<div className='headerRowSelection'>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Months</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Months"
							>
								<MenuItem value={1}>January</MenuItem>
								<MenuItem value={2}>February</MenuItem>
								<MenuItem value={3}>March</MenuItem>
								<MenuItem value={4}>April</MenuItem>
								<MenuItem value={5}>May</MenuItem>
								<MenuItem value={6}>June</MenuItem>
								<MenuItem value={7}>July</MenuItem>
								<MenuItem value={8}>August</MenuItem>
								<MenuItem value={9}>September</MenuItem>
								<MenuItem value={10}>October</MenuItem>
								<MenuItem value={11}>November</MenuItem>
								<MenuItem value={12}>December</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Year</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Year"
							>
								<MenuItem value={1}>2014</MenuItem>
								<MenuItem value={2}>2015</MenuItem>
								<MenuItem value={3}>2016</MenuItem>
								<MenuItem value={4}>2017</MenuItem>
								<MenuItem value={5}>2018</MenuItem>
								<MenuItem value={6}>2019</MenuItem>
								<MenuItem value={7}>2020</MenuItem>
								<MenuItem value={8}>2021</MenuItem>
								<MenuItem value={9}>2022</MenuItem>
								<MenuItem value={10}>2023</MenuItem>
								<MenuItem value={11}>2024</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='monthAnd_years'><strong>{currentDate}</strong></div>
				<div className='table-responsive attend_btl'>
					<table className='table table-bordered'>
						<thead>
							<tr className='daysRow'>
								<th><div className='days_box'>SUN</div></th>
								<th><div className='days_box'>MON</div></th>
								<th><div className='days_box'>TUE</div></th>
								<th><div className='days_box'>WED</div></th>
								<th><div className='days_box'>THU</div></th>
								<th><div className='days_box'>FRI</div></th>
								<th><div className='days_box'>SAT</div></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><div className='date_ShiftShe offf'>1</div></td>
								<td><div className='date_ShiftShe'>2</div></td>
								<td><div className='date_ShiftShe'>3</div></td>
								<td><div className='date_ShiftShe'>4</div></td>
								<td><div className='date_ShiftShe'>5</div></td>
								<td><div className='date_ShiftShe'>6</div></td>
								<td><div className='date_ShiftShe'>7</div></td>
							</tr>
							<tr>
								<td><div className='date_ShiftShe offf'>8</div></td>
								<td><div className='date_ShiftShe'>9</div></td>
								<td><div className='date_ShiftShe'>10</div></td>
								<td><div className='date_ShiftShe'>11</div></td>
								<td><div className='date_ShiftShe'>12</div></td>
								<td><div className='date_ShiftShe'>13</div></td>
								<td><div className='date_ShiftShe offf'>14</div></td>
							</tr>
							<tr>
								<td><div className='date_ShiftShe offf'>15</div></td>
								<td><div className='date_ShiftShe'>16</div></td>
								<td><div className='date_ShiftShe '>17</div></td>
								<td><div className='date_ShiftShe '>18</div></td>
								<td><div className='date_ShiftShe'>19</div></td>
								<td><div className='date_ShiftShe'>20</div></td>
								<td><div className='date_ShiftShe'>21</div></td>
							</tr>
							<tr>
								<td><div className='date_ShiftShe offf'>22</div></td>
								<td><div className='date_ShiftShe'>23</div></td>
								<td><div className='date_ShiftShe'>24</div></td>
								<td><div className='date_ShiftShe'>25</div></td>
								<td><div className='date_ShiftShe'>26</div></td>
								<td><div className='date_ShiftShe'>27</div></td>
								<td><div className='date_ShiftShe'>28</div></td>
							</tr>
							<tr>
								<td><div className='date_ShiftShe offf'>29</div></td>
								<td><div className='date_ShiftShe'>30</div></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<div className='rowForcolm'>
						<div className='colms'> <div className='colorBox'></div> - Week Off </div>
						{/* <div className='colms'> <div className='colorBox present'></div> - Present </div>
						<div className='colms'> <div className='colorBox absent'></div> - Absent </div> */}
					</div>
				</div>

				<div className='table-responsive attend_btl'>
					<table className='table table-bordered'>
							<tr className='trRowAtten_2'>
								<td>Start Time</td>
								<td>End Time</td>
								<td>Working Days</td>
								<td>Week-Offs</td>
								<td>Holidays</td>
								<td>Field Break Days</td>
								<td>Rest Days</td>
							</tr>
						<tbody>
							<tr>
								<td>IN</td>
								<td>_</td>
								<td>15</td>
								<td>3</td>
								<td>NA</td>
								<td>NA</td>
								<td>NA</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>


		</>
	);
}
export default EmplShiftSchedule;
