import '../css/HrStyle.css';
import '../../../Media.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
function ActivityLog() {
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Activity Log</div>
					<div className='col-auto px-1'>
						<button className='btn p-1 btn-sm'><ArrowBackIcon /></button>
					</div>
				</div>
				<div className='row g-0 justify-content-center animate__animated animate__fadeInDown'>
					<div className='col-md-8 col-sm-10 col-12'>
						<div className='row gx-3'>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm" label="Start Date" slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm" label="End Date" slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
						</div>
					</div>
					<div className='col-md-8 col-sm-10 col-12 inputTextFiled'>
						<FormControl size='small' fullWidth>
							<InputLabel id="demo-simple-select-label">Login User</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Login User"
							>
								<MenuItem value={1}>All</MenuItem>
								<MenuItem value={2}>System Accounts</MenuItem>
								<MenuItem value={3}>User</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='col-md-8 col-sm-10 col-12'>
						<div className='row gx-3'>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<TextField type='text' autoComplete="off" required disabled fullWidth id="" label="User ID" size='small' variant="outlined" />
							</div>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<TextField type='text' autoComplete="off" required disabled fullWidth id="" label="User Name" size='small' variant="outlined" />
							</div>
						</div>
					</div>
					<div className='col-md-8 col-sm-10 col-12 inputTextFiled'>
						<FormControl size='small' fullWidth>
							<InputLabel id="demo-simple-select-label">Table</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Table"
							>
								<MenuItem value={1}>All</MenuItem>
								<MenuItem value={2}>Alert Application</MenuItem>
								<MenuItem value={3}>ARC IO 800 Input Config</MenuItem>
								<MenuItem value={3}>ARC IO 800 Output Config</MenuItem>
								<MenuItem value={3}>Enrollment Application</MenuItem>
								<MenuItem value={3}>Monitor</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='col-md-8 col-sm-10 col-12 inputTextFiled'>
						<FormControl size='small' fullWidth>
							<InputLabel id="demo-simple-select-label">Field</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Field"
							>
								<MenuItem value={1}>All</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='col-md-8 col-sm-10 col-12 inputTextFiled'>
						<TextField type='text' autoComplete="off" fullWidth id="" label="Value" size='small' variant="outlined" />
					</div>
					<div className='col-md-8 col-sm-10 col-12 inputTextFiled text-center'>
						<Button variant="contained" className='px-5'>View</Button>
					</div>
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default ActivityLog;
