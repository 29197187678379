import '../css/HrStyle.css';
import '../../../Media.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function DownloadManager() {
	return (
		<>
			<div className='white_bgs position-relative'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Download Manager</div>
					<div className='col-auto px-1'>
						<button className='btn p-1 btn-sm'><ArrowBackIcon /></button>
					</div>
				</div>
				<div className='row g-0 justify-content-center animate__animated animate__fadeInDown'>
					<div className='col-md-11 col-sm-11 col-12 inputTextFiled'>
						<div className='row g-0'>
							<div className='col-md-4 border p-2'>
								Cosec Version
							</div>
							<div className='col-md-8 border p-2'>
								V18R01
							</div>
							<div className='col-md-4 border p-2'>
								Enroll
							</div>
							<div className='col-md-8 border p-2'>
								Cosec Enroll is used to enroll biometrics/card/signature of user/visitors to provide comprehensive access control system.
							</div>
							<div className='col-md-4 border p-2'>
								Integrate
							</div>
							<div className='col-md-8 border p-2'>
								COSEC Integrate is used to export/import data form COSEC database to 3<sup>rd</sup> party database and vice versa.
							</div>
							<div className='col-md-4 border p-2'>
								VMS
							</div>
							<div className='col-md-8 border p-2'>
								COSEC VMS is used in tracking and monitoring visitors to the premise in an efficient & comprehensive manner.
							</div>
							<div className='col-md-4 border p-2'>
								Identification Server
							</div>
							<div className='col-md-8 border p-2'>
								Identification Servier is used for faster Finger/Palm Template Identification across a Multi-site Installation.
							</div>
						</div>
					</div>
				</div>
				<div className='position-absolute start-0 bottom-0 w-100 p-2'>
					NOTE: Please consider synchronizing your system with NTP server when any of the above mentioned application is installed.
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default DownloadManager;
