import '../css/HrStyle.css';
import '../../../Media.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Accordion from 'react-bootstrap/Accordion';
import LicenseModulesList from './LicenseModulesList';
import Button from '@mui/material/Button';
function LicenseInformation() {
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>License Information</div>
					<div className='col-auto px-1'>
						<button className='btn p-1 btn-sm'><ArrowBackIcon /></button>
					</div>
				</div>
				<div className='row g-0 justify-content-center animate__animated animate__fadeInDown'>
					<div className='col-md-8 col-sm-10 col-12'>
						<div className='row gx-3'>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<TextField type='text' autoComplete="off" fullWidth id="" label="Product Variant" size='small' variant="outlined" />
							</div>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<TextField type='text' autoComplete="off" fullWidth id="" label="License Key" size='small' variant="outlined" />
							</div>
						</div>
					</div>
					<div className='col-md-12 col-sm-12 col-12 inputTextFiled accordionBgClr'>
						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
								<Accordion.Header>License Details</Accordion.Header>
								<Accordion.Body className='p-3'>
									<div className='row g-0 justify-content-center'>
										<div className='col-md-12 col-sm-10 col-12'>
											<LicenseModulesList />
											<Button className='inputTextFiled mt-3' variant="contained">
												End User License Aggrement
											</Button>
										</div>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default LicenseInformation;
