import '../css/HrStyle.css';
import '../../../Media.css';
import { useRef } from 'react';
import Table from 'react-bootstrap/Table';
function TaskSchedulerList() {
	const inputBox = useRef(null);
	const table = useRef(null);
	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='UserList'>
				<input className="form-control searchBoxAdmi shadow-sm" ref={inputBox} onKeyUp={myFunction} type="text" placeholder="Search Schedule" />
				<div className='userIDData'>
					<Table hover bordered responsive align='' ref={table}>
						<thead className='bg-info'>
							<tr>
								<th>ID</th>
								<th>Schedule Name</th>
							</tr>
						</thead>
						<tbody >
							<tr>
								<td>1</td>
								<td>Backup Jan2021</td>
							</tr>
							<tr>
								<td>2</td>
								<td>SQLHRMS</td>
							</tr>
							<tr>
								<td>3</td>
								<td>SHIFT PROCESS</td>
							</tr>
							<tr>
								<td>4</td>
								<td>MONTH ATTEN PROCESS CURRENT</td>
							</tr>
							<tr>
								<td>6</td>
								<td>Left Employees De-activate</td>
							</tr>
							<tr>
								<td>7</td>
								<td>Half CL Credit</td>
							</tr>
							<tr>
								<td>8</td>
								<td>Half CL Credit</td>
							</tr>
							<tr>
								<td>9</td>
								<td>MONTHLY ATTEN PROCESS PREV</td>
							</tr>
							<tr>
								<td>10</td>
								<td>BACKUP</td>
							</tr>
							<tr>
								<td>11</td>
								<td>CL Credit_Schedule</td>
							</tr>
							<tr>
								<td>12</td>
								<td>EL Credit_Schedule</td>
							</tr>							
						</tbody>
					</Table>
				</div>
			</div>

		</>
	);
}
export default TaskSchedulerList;
