import '../css/HrStyle.css';
import '../../../Media.css';
import { useRef } from 'react';
import Table from 'react-bootstrap/Table';

function ScheduleList() {
	const inputBox = useRef(null);
	const table = useRef(null);
	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='UserList'>
				<input className="form-control searchBoxAdmi shadow-sm w-auto" type="text" ref={inputBox}
					onKeyUp={myFunction} placeholder="Search user" />
				<div className='userIDData'>
					<Table hover bordered responsive align='' ref={table}>
						<thead className='bg-info'>
							<tr>
								<th>Task/Report Name</th>
								<th>Scheduled Date-Time</th>
								<th>Completion Date-Time</th>
								<th>Status</th>
								<th>Status Description</th>
							</tr>
						</thead>
						<tbody >
							<tr>
								<td>1</td>
								<td>12/12/2024</td>
								<td>12/12/2025</td>
								<td>Succeed</td>
								<td>User Not Found</td>
							</tr>
							<tr>
								<td>2</td>
								<td>31/12/2024</td>
								<td>31/12/2025</td>
								<td>Succeed</td>
								<td>User Not Found</td>
							</tr>
							<tr>
								<td>3</td>
								<td>21/12/2024</td>
								<td>21/12/2025</td>
								<td>Succeed</td>
								<td>User Not Found</td>
							</tr>
							<tr>
								<td>4</td>
								<td>19/12/2024</td>
								<td>19/12/2025</td>
								<td>Succeed</td>
								<td>User Not Found</td>
							</tr>
							<tr>
								<td>5</td>
								<td>14/12/2024</td>
								<td>14/12/2025</td>
								<td>failed</td>
								<td>User Found</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

		</>
	);
}
export default ScheduleList;
