import '../css/HrStyle.css';
import '../../../Media.css';
import { useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
function UserListFor_Admin() {
	const inputBox = useRef(null);
	const table = useRef(null);
	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='UserList'>
				<input className="form-control searchBoxAdmi shadow-sm" ref={inputBox} onKeyUp={myFunction} type="text" placeholder="Search user" />
				<div className='userIDData'>
					<Table hover bordered responsive align='' ref={table}>
						<thead className='bg-info'>
							<tr>
								<th style={{ width: "50px" }}>Sr.N.</th>
								<th>ID</th>
								<th>Name</th>
							</tr>
						</thead>
						<tbody >
							<tr>
								<td>1</td>
								<td>10011</td>
								<td>RAM KRISHAN DWI</td>
							</tr>
							<tr>
								<td>2</td>
								<td>10012</td>
								<td>MOHAN SINGH</td>
							</tr>
							<tr>
								<td>3</td>
								<td>104</td>
								<td>HARI KRISHAN PANT</td>
							</tr>
							<tr>
								<td>4</td>
								<td>105</td>
								<td>SANDEEP SHARMA</td>
							</tr>
							<tr>
								<td>6</td>
								<td>106</td>
								<td>GORAV SHRIVASTA</td>
							</tr>
							<tr>
								<td>7</td>
								<td>107</td>
								<td>HIMANSHU SURYAN</td>
							</tr>
							<tr>
								<td>8</td>
								<td>108</td>
								<td>AKSHYA MISHRA</td>
							</tr>
							<tr>
								<td>9</td>
								<td>109</td>
								<td>SANDEEP SHARMA</td>
							</tr>
							<tr>
								<td>10</td>
								<td>110</td>
								<td>AKSHYA MISHRA</td>
							</tr>
							<tr>
								<td>11</td>
								<td>110</td>
								<td>AKASH SAINI</td>
							</tr>
							<tr>
								<td>12</td>
								<td>110</td>
								<td>RAJKUMAR SINGH</td>
							</tr>
							<tr>
								<td>13</td>
								<td>258</td>
								<td>TISHA GOEL</td>
							</tr>
							<tr>
								<td>14</td>
								<td>117</td>
								<td>MONU KUMAR</td>
							</tr>
							<tr>
								<td>15</td>
								<td>119</td>
								<td>SHARMA JI</td>
							</tr>
							<tr>
								<td>16</td>
								<td>119</td>
								<td>CHIRAG BHARATI</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

		</>
	);
}
export default UserListFor_Admin;
