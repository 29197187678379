import '../css/HrStyle.css';
import '../../../Media.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import ReplayIcon from '@mui/icons-material/Replay';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import TaskSchedulerList from './TaskSchedulerList';

function TaskScheduler() {
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Report Scheduler</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CreateIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'>
							<Link to='/hr-dashboard'><ReplayIcon /></Link>
						</button>
					</div>
				</div>
				<div className='row g-0 justify-content-center animate__animated animate__fadeInDown'>
					<div className='col-md-8 col-sm-8 col-12'>
						<div className='row g-0 justify-content-center' style={{}}>
							<div className='col-md-11 col-sm-10 col-12'>
								<div className='row gx-3'>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label">Schedule Type</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>Reports</MenuItem>
												<MenuItem value={2}>Reports</MenuItem>
												<MenuItem value={3}>Reports</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<TextField type='text' autoComplete="off" fullWidth id="" label="Schedule Name" required size='small' variant="outlined" />
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label">Module</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>Time and Attendance</MenuItem>
												<MenuItem value={2}>Time and Attendance</MenuItem>
												<MenuItem value={3}>Time and Attendance</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label">Parent Menu</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>Time Management</MenuItem>
												<MenuItem value={2}>Time Management</MenuItem>
												<MenuItem value={3}>Time Management</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label">Report</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>Attendance</MenuItem>
												<MenuItem value={2}>Attendance</MenuItem>
												<MenuItem value={3}>Attendance</MenuItem>
											</Select>
										</FormControl>
									</div>
								</div>
							</div>
							<div className='col-md-11 col-sm-10 col-12'>
								<FormControlLabel
									value="Active"
									control={<Checkbox />}
									label="Active"
									labelPlacement="Right"
								/>
							</div>
							<div className='col-md-11 col-sm-10 col-12 inputTextFiled mb-0'>
								<h6>Send Email Notification</h6>
								<hr />
							</div>
							<div className='col-md-11 col-sm-10 col-12'>
								<div className='row gx-3'>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label" required>Send Report To</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>Configured Email ID</MenuItem>
												<MenuItem value={2}>Configured Email ID</MenuItem>
												<MenuItem value={3}>Configured Email ID</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<TextField type='text' autoComplete="off" fullWidth id="" label="Email ID" required size='small' variant="outlined" />
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<FormControl size='small' fullWidth>
											<InputLabel id="demo-simple-select-label">Report Format</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Task"
											>
												<MenuItem value={1}>PDF</MenuItem>
												<MenuItem value={2}>PDF</MenuItem>
												<MenuItem value={3}>PDF</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<TextField type='text' autoComplete="off" fullWidth id="" label="Message" size='small' variant="outlined" />
									</div>
								</div>
							</div>
							<div className='col-md-11 col-sm-10 col-12 inputTextFiled mb-0'>
								<h6>Schedule Parameter</h6>
								<hr />
							</div>
							<div className='col-md-11 col-sm-10 col-12 d-none'>
								<div className='row gx-3'>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['TimePicker']}>
												<TimePicker label="Basic time picker" />
											</DemoContainer>
										</LocalizationProvider>
									</div>
									<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
										<TextField type='text' autoComplete="off" fullWidth id="" label="Schedule Name" required size='small' variant="outlined" />
									</div>
								</div>
							</div>
							<div className='col-md-11 col-sm-10 col-12 inputTextFiled'>
								<FormControlLabel
									value="Sun"
									control={<Checkbox />}
									label="Sun"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Mon"
									control={<Checkbox />}
									label="Mon"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Tue"
									control={<Checkbox />}
									label="Tue"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Wed"
									control={<Checkbox />}
									label="Wed"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Thu"
									control={<Checkbox />}
									label="Thu"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Fri"
									control={<Checkbox />}
									label="Fri"
									labelPlacement="Right"
								/>
								<FormControlLabel
									value="Sat"
									control={<Checkbox />}
									label="Sat"
									labelPlacement="Right"
								/>
							</div>
							<div className='col-md-11 col-sm-10 col-12 inputTextFiled'>
								<TextField type='text' autoComplete="off" fullWidth id="" label="Schedule Run Time" required size='small' variant="outlined" />
							</div>
							<div className='col-md-11 col-sm-10 col-12 inputTextFiled'>
								<div className='accordionBgClr'>
									<Accordion defaultActiveKey="0">
										<Accordion.Item eventKey="0">
											<Accordion.Header>Task Parameters</Accordion.Header>
											<Accordion.Body className='p-3'>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-4 col-sm-4 col-12'>
						<TaskSchedulerList />
					</div>
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default TaskScheduler;
