import UserDetails from '../UserDetails';
import '../css/HrStyle.css';
import '../../../Media.css';
import Accordion from 'react-bootstrap/Accordion';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import PreviewIcon from '@mui/icons-material/Preview';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DvrIcon from '@mui/icons-material/Dvr';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import $ from 'jquery';
import QuickLinks from './QuickLinks';
import { useState } from 'react';
import System_account from './System_account';
import GlobalPolicy from './GlobalPolicy';
import IdentificationServer from './IdentificationServer';
import ActivityLog from './ActivityLog';
import EventView from './EventView';
import AlertView from './AlertView';
import SchedulerLog from './SchedulerLog';
import LicenseInformation from './LicenseInformation';
import DownloadManager from './DownloadManager';
import TaskScheduler from './TaskScheduler';
import ReportScheduler from './ReportScheduler';
import HomeIcon from '@mui/icons-material/Home';
function HrAdmin() {

	function menuLeft(id) {
		if (id == 1) {
			$(".hrleftMenu").css('display', 'block').animate({ "left": "0" }, 5);
			$("#onBtn").css("display", "none");
			$("#offBtn").css("display", "block");
		}
		else {
			$(".hrleftMenu").css('display', 'none').animate({ "left": "-260px" }, 5);
			$("#onBtn").css("display", "block");
			$("#offBtn").css("display", "none");
		}
	}

	const [hrMuduls, sethrMuduls] = useState({
		selectedData: false,
		systemAccount: true,
		gloablPolicy: false,
		identificationServer: false,
		activityLog: false,
		eventView: false,
		alertView: false,
		schedulerLog: false,
		licenseInformation: false,
		downloadManager: false,
		taskScheduler: false,
		reportScheduler: false,
	})

	function adminLeftMenu(get_para) {
		let action = get_para;
		if (action == "home") {
			sethrMuduls((old) => {
				return {
					...old,
					selectedData: true,
					systemAccount: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "systemAccount") {
			sethrMuduls((old) => {
				return {
					...old,
					selectedData: false,
					systemAccount: true,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "global_policy") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: true,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "identi_server") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: true,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "activity_log") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: true,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "event_view") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: true,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "alert_view") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: true,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "scheduler_log") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: true,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "license_information") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: true,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "download_manager") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: true,
					taskScheduler: false,
					reportScheduler: false
				}
			})
		}
		else if (action == "task_scheduler") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: true,
					reportScheduler: false
				}
			})
		}
		else if (action == "report_scheduler") {
			sethrMuduls((old) => {
				return {
					...old,
					systemAccount: false,
					selectedData: false,
					gloablPolicy: false,
					identificationServer: false,
					activityLog: false,
					eventView: false,
					alertView: false,
					schedulerLog: false,
					licenseInformation: false,
					downloadManager: false,
					taskScheduler: false,
					reportScheduler: true
				}
			})
		}
	}
	return (
		<>
			<div className="headerBck shadow-sm">
				<div className="rowHeaders">
					<div className='hrandroidItem'>
						<ArrowCircleRightIcon className='hrmenunuIcons' id="onBtn" onClick={() => { menuLeft(1) }} />
						<ArrowCircleLeftIcon className='hrmenunuIcons' id="offBtn" onClick={() => { menuLeft(2) }} />
					</div>
					<div className="dashBoardText"><AdminPanelSettingsIcon /> HR Admin</div>
					<div className="userDetails">
						<UserDetails />
					</div>
				</div>
			</div>
			<section>
				<div className='hrleftMenu shadow-sm'>

					<div className='d-flex border-bottom border-light text-white text-nowrap' role='button'>
						<Accordion className='w-100 p-0' flush>
							<span className='withOut_icn'>
								<Accordion.Item className={hrMuduls.selectedData ? 'actiove_Menu' : 'border-bottom border-light'} eventKey="5" onClick={() => { adminLeftMenu('home') }}>
									<Accordion.Header><div className='iconsDiv'><HomeIcon /></div><div className='textHide'>Home</div></Accordion.Header>
								</Accordion.Item>
								<Accordion.Item className={hrMuduls.systemAccount ? 'actiove_Menu' : 'border-bottom border-light'} eventKey="6" onClick={() => { adminLeftMenu('systemAccount') }}>
									<Accordion.Header><div className='iconsDiv'><DvrIcon /></div><div className='textHide'>System Account</div></Accordion.Header>
								</Accordion.Item>
							</span>
							<Accordion.Item className='border-bottom border-light' eventKey="0">
								<Accordion.Header><div className='iconsDiv'><InstallDesktopIcon /></div><div className='textHide'>System Configuration</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDetH hrLi p-0'>
										<li className={hrMuduls.gloablPolicy ? 'liActive' : ''} onClick={() => { adminLeftMenu('global_policy') }}>Gloabl Policy</li>
										<li className={hrMuduls.identificationServer ? 'liActive' : ''} onClick={() => { adminLeftMenu('identi_server') }}>Identification Server Confi.</li>
										<li>SMS Configuration</li>
										<li>Email Configuration</li>
										<li>Rename Group</li>
										<li>Enterprise Profile</li>
										<li>Alert Message Configuration</li>
										<li>Custom Message</li>
										<li>Loction Master</li>
										<li>Location Group</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className='border-bottom border-light' eventKey="1">
								<Accordion.Header><div className='iconsDiv'><ConnectedTvIcon /></div><div className='textHide'>System Utilities</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDetH hrLi bgClr'>
										<li>Import Data</li>
										<li>Export Data</li>
										<li className='p-0'>
											<Accordion className='w-100 p-0' flush>
												<Accordion.Item className='border-0' eventKey="2">
													<Accordion.Header><div className='iconsDiv'><InstallDesktopIcon /></div><div className='textHide'>Third Party Export</div></Accordion.Header>
													<Accordion.Body className='p-0'>
														<ul className='listDetH hrLi'>
															<li className={`${hrMuduls.taskScheduler ? 'liActive' : ''} border-top`} onClick={() => { adminLeftMenu('task_scheduler') }}>Task Scheduler</li>
															<li className={`${hrMuduls.reportScheduler ? 'liActive' : ''} border-top`} onClick={() => { adminLeftMenu('report_scheduler') }}>Report Scheduler</li>
															<li>Event Scheduler</li>
															<li>Message Board</li>
															<li>Manage Database</li>
														</ul>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header><div className='iconsDiv'><PreviewIcon /></div><div className='textHide'>Views/Logs</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDetH hrLi'>
										<li className={hrMuduls.activityLog ? 'liActive' : ''} onClick={() => { adminLeftMenu('activity_log') }}>Activity Log</li>
										<li className={hrMuduls.eventView ? 'liActive' : ''} onClick={() => { adminLeftMenu('event_view') }}>Event View</li>
										<li className={hrMuduls.alertView ? 'liActive' : ''} onClick={() => { adminLeftMenu('alert_view') }}>Alert View</li>
										<li className={hrMuduls.schedulerLog ? 'liActive' : ''} onClick={() => { adminLeftMenu('scheduler_log') }}>Scheduler Log</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					<div className={`${hrMuduls.licenseInformation ? 'actiove_Menu' : 'border-bottom border-light'} d-flex border-bottom border-light text-white py-2 px-1 menubtn`} role='button' onClick={() => { adminLeftMenu('license_information') }}>
						<div className='iconsDiv'><RecentActorsIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>License Informations</div>
					</div>
					<div className={`${hrMuduls.downloadManager ? 'actiove_Menu' : 'border-bottom border-light'} d-flex border-bottom border-light text-white py-2 px-1 menubtn`} role='button' onClick={() => { adminLeftMenu('download_manager') }}>
						<div className='iconsDiv'><SystemUpdateAltIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>Download Manager</div>
					</div>
				</div>
				<div className='rightBox p-2'>
					{hrMuduls.selectedData && <QuickLinks />}
					{hrMuduls.systemAccount && <System_account />}
					{hrMuduls.gloablPolicy && <GlobalPolicy />}
					{hrMuduls.identificationServer && <IdentificationServer />}
					{hrMuduls.activityLog && <ActivityLog />}
					{hrMuduls.eventView && <EventView />}
					{hrMuduls.alertView && <AlertView />}
					{hrMuduls.schedulerLog && <SchedulerLog />}
					{hrMuduls.licenseInformation && <LicenseInformation />}
					{hrMuduls.downloadManager && <DownloadManager />}
					{hrMuduls.taskScheduler && <TaskScheduler />}
					{hrMuduls.reportScheduler && <ReportScheduler />}
				</div>
			</section >
		</>
	);
}
export default HrAdmin;
