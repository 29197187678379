import '../css/HrStyle.css';
import '../../../Media.css';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplayIcon from '@mui/icons-material/Replay';
import DvrIcon from '@mui/icons-material/Dvr';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddchartIcon from '@mui/icons-material/Addchart';
import ExtensionIcon from '@mui/icons-material/Extension';
import { Link } from 'react-router-dom';
function QuickLinks() {
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Quick Links</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CalendarMonthIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'><Link to='/hr-dashboard'><ReplayIcon /></Link></button>
					</div>
				</div>
				<div className='row_cricle_btn'>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						System Account
						<div className='cricleBtns shadow-sm'><DvrIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Global Policy
						<div className='cricleBtns shadow-sm'><TravelExploreIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Alert Message Con..
						<div className='cricleBtns shadow-sm'><NearbyErrorIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Import Data
						<div className='cricleBtns shadow-sm'><PublishedWithChangesIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Export Data
						<div className='cricleBtns shadow-sm'><CallMissedOutgoingIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Task Scheduler
						<div className='cricleBtns shadow-sm'><EditCalendarIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Report Scheduler
						<div className='cricleBtns shadow-sm'><AddchartIcon /></div>
					</div>
					<div className='buttonBgs animate__animated animate__fadeInDown shadow-sm'>
						Activity log
						<div className='cricleBtns shadow-sm'><ExtensionIcon /></div>
					</div>
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default QuickLinks;
