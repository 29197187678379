import '../css/HrStyle.css';
import '../../../Media.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Accordion from 'react-bootstrap/Accordion';
function EventView() {
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Event View</div>
					<div className='col-auto px-1'>
						<button className='btn p-1 btn-sm'><ArrowBackIcon /></button>
					</div>
				</div>
				<div className='row g-0 justify-content-center animate__animated animate__fadeInDown'>
					<div className='col-md-8 col-sm-10 col-12'>
						<div className='row gx-3'>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm" label="Start Date" slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
							<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm" label="End Date" slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
						</div>
					</div>
					<div className='col-md-8 col-sm-10 col-12 text-center'>
						<Button variant="contained" className='px-5 inputTextFiled'>View</Button>
					</div>
					<div className='accordionBgClr inputTextFiled'>
						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
								<Accordion.Header>Filter</Accordion.Header>
								<Accordion.Body className='p-3'>
									<div className='row g-0 justify-content-center'>
										<div className='col-md-8 col-sm-10 col-12'>
											<div className='row gx-3'>
												<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Door Selection</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Door Selection"
														>
															<MenuItem value={1}>All</MenuItem>
															<MenuItem value={2}>1</MenuItem>
															<MenuItem value={3}>2</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Event Status</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Event Status"
														>
															<MenuItem value={1}>Both</MenuItem>
															<MenuItem value={2}>1</MenuItem>
															<MenuItem value={3}>2</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Credentials</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Credentials"
														>
															<MenuItem value={1}>All</MenuItem>
															<MenuItem value={2}>1</MenuItem>
															<MenuItem value={3}>2</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div className='col-md-6 col-sm-6 col-12 inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Select Users</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Select Users"
														>
															<MenuItem value={1}>All</MenuItem>
															<MenuItem value={2}>1</MenuItem>
															<MenuItem value={3}>2</MenuItem>
														</Select>
													</FormControl>
												</div>
											</div>
										</div>
										<div className='col-md-8 col-sm-10 col-12 inputTextFiled'>
											<FormControl size='small' fullWidth>
												<InputLabel id="demo-simple-select-label">User</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="User"
													required
												>
													<MenuItem value={1}>All</MenuItem>
													<MenuItem value={2}>1</MenuItem>
													<MenuItem value={3}>2</MenuItem>
												</Select>
											</FormControl>
										</div>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
				<div className='clear'></div>
			</div>
		</>
	);
}
export default EventView;
