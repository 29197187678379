import '../css/HrStyle.css';
import '../../../Media.css';
import { useState, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplayIcon from '@mui/icons-material/Replay';
import { Link } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ModeIcon from '@mui/icons-material/Mode';
function GlobalPolicy() {


	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Global Policy</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CalendarMonthIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'><Link to='/hr-dashboard'><ReplayIcon /></Link></button>
					</div>
				</div>
				<div className='clear'></div>
				<Container fluid className='animate__animated animate__fadeInDown'>
					<Row>
						<Col className='col-md-12 col-sm-12 col-12'>
							<div className='mainBoxTabs'>

								<div className='accordionBgClr'>
									<Accordion defaultActiveKey="0">
										<Accordion.Item eventKey="0">
											<Accordion.Header>Basic</Accordion.Header>
											<Accordion.Body className='p-3'>
												<div className='inputTextFiled'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Create Activity Log"
														labelPlacement="Right"
													/>
												</div>
												<div className='inputTextFiled'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Auto Login to Monitor"
														labelPlacement="Right"
													/>
												</div>
												<div className='inputTextFiled'>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DemoContainer components={['DatePicker']}>
															<DatePicker size="sm" requre label="System Date Format" slotProps={{ textField: { size: 'small' } }} />
														</DemoContainer>
													</LocalizationProvider>
												</div>
												<hr />
												<h6>Google API Key</h6>
												<div className='inputTextFiled'>
													<TextField autoComplete="off" required id="outlined-basic" label="API Key" size='small' variant="outlined" />
												</div>
												<button className='btn btn-outline-success'>Save</button>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="1">
											<Accordion.Header>User</Accordion.Header>
											<Accordion.Body>
												<div className='inputTextFiled col-md-6 col-12'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Save FB Image"
														labelPlacement="Right"
													/>
												</div>
												<Row>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Template Per Finger</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Template Per Finger"
															>
																<MenuItem value={1}>Dual Finger</MenuItem>
																<MenuItem value={2}>1</MenuItem>
																<MenuItem value={3}>2</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Maximum No. of Fingers</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Maximum No. of Fingers"
															>
																<MenuItem value={1}>One</MenuItem>
																<MenuItem value={2}>Two</MenuItem>
																<MenuItem value={3}>Four</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Finger one device per user</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Finger one device per user"
															>
																<MenuItem value={1}>One</MenuItem>
																<MenuItem value={2}>Two</MenuItem>
																<MenuItem value={3}>Four</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Maximum no of Palm Template</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Maximum no of Palm Template"
															>
																<MenuItem value={1}>One</MenuItem>
																<MenuItem value={2}>Two</MenuItem>
																<MenuItem value={3}>Four</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Palm Template one device per user</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Palm Template one device per user"
															>
																<MenuItem value={1}>One</MenuItem>
																<MenuItem value={2}>Two</MenuItem>
																<MenuItem value={3}>Four</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Maximum No of Faces</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Maximum No of Faces"
															>
																<MenuItem value={1}>1</MenuItem>
																<MenuItem value={2}>2</MenuItem>
																<MenuItem value={3}>3</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Self-Enrollment Retry Cunt</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Self-Enrollment Retry Cunt"
															>
																<MenuItem value={1}>1</MenuItem>
																<MenuItem value={2}>2</MenuItem>
																<MenuItem value={3}>3</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<button className='btn btn-outline-success' style={{ width: "80px" }}>Save</button>
													</div>
													<h6>Custom Fields</h6>
													<hr />
													<div className='table-responsive'>
														<table className='table table-bordered table-hover tblsRols'>
															<thead>
																<tr className='alignTopSect success' valign="middle">
																	<th style={{ width: "50px" }}>Field No.</th>
																	<th>Active</th>
																	<th>Field Name</th>
																	<th>Type</th>
																	<th>Upload</th>
																	<th>Mandatory</th>
																	<th>Edit</th>
																</tr>
															</thead>
															<tbody>
																<tr className='' valign="middle">
																	<td>1</td>
																	<td>Yes</td>
																	<td>Field 1</td>
																	<td>Text Book</td>
																	<td>Yes</td>
																	<td>No</td>
																	<td><button className='btn btn-outline-primary btn-sm'><ModeIcon /></button></td>
																</tr>
															</tbody>
														</table>
													</div>



												</Row>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="2">
											<Accordion.Header>Login</Accordion.Header>
											<Accordion.Body>
												<div className='inputTextFiled col-12'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Login Policy"
														labelPlacement="Right"
													/>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Local Via SSO"
														labelPlacement="Right"
													/>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Restrict Local Login"
														labelPlacement="Right"
													/>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Secure Connection"
														labelPlacement="Right"
													/>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Enable Notivation"
														labelPlacement="Right"
													/>
												</div>
												<Row>
													<div className='inputTextFiled col-md-6 col-12'>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Maximum days allowed without login</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Maximum days allowed without login"
															>
																<MenuItem value={1}>0</MenuItem>
																<MenuItem value={2}>1</MenuItem>
																<MenuItem value={3}>2</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<TextField type='password' autoComplete="off" fullWidth required id="" label="Password" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Server Address" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Domain Name" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled col-md-6 col-12'>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Key Size" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled col-md-12 col-12'>
														<button className='btn btn-outline-success' style={{ width: "80px" }}>Save</button>
													</div>

												</Row>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>Password Policy</Accordion.Header>
											<Accordion.Body>
												<div className='inputTextFiled col-12'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Password Security Policy"
														labelPlacement="Right"
													/>

												</div>
												<Row>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Password reset period (Days)" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Minimum required chracters" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Security lavel</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Security level"
															>
																<MenuItem value={1}>Low</MenuItem>
																<MenuItem value={2}>High</MenuItem>
																<MenuItem value={3}>Normal</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="Lock account for invailid Login attempts"
															labelPlacement="Right"
														/>
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Minimum Invalid login attempts" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Auto unlock timer (min)" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="Deny Password Reuse"
															labelPlacement="Right"
														/>
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Reuse count for system account user" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Reuse count for ESS users" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="OTP Authentication For Setting Password"
															labelPlacement="Right"
														/>
													</div>
												</Row>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="4">
											<Accordion.Header>Device</Accordion.Header>
											<Accordion.Body>
												<div className='inputTextFiled col-12'>
													<FormControlLabel
														value="Active"
														control={<Checkbox />}
														label="Active"
														labelPlacement="Right"
													/>
												</div>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="5">
											<Accordion.Header>Time Attendance</Accordion.Header>
											<Accordion.Body>
												<Row>
													<h6>Attendance Process Calibration</h6>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Maxmum Early-IN Allowed (Hrs)" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id="" label="Maxmum Late-Out Allowed (Hrs)" size='small' variant="outlined" />
													</div>
													<div className='inputTextFiled '>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Priority</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Priority"
															>
																<MenuItem value={1}>Late Out</MenuItem>
																<MenuItem value={2}>....</MenuItem>
																<MenuItem value={3}>....</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled '>
														<TextField type='text' autoComplete="off" fullWidth required id=""
															label="Maximum working hours Per Day" size='small' variant="outlined" />
													</div>
												</Row>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="6">
											<Accordion.Header>Report</Accordion.Header>
											<Accordion.Body>
												<Row>
													<div className='inputTextFiled '>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">User ID</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="User ID"
															>
																<MenuItem value={1}>100</MenuItem>
																<MenuItem value={2}>500</MenuItem>
																<MenuItem value={3}>900</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled '>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Report Print Output</InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Report Print Output"
															>
																<MenuItem value={1}>Printer</MenuItem>
																<MenuItem value={2}>.</MenuItem>
																<MenuItem value={3}>..</MenuItem>
															</Select>
														</FormControl>
													</div>
													<div className='inputTextFiled '>
														<FormControl size='small' fullWidth>
															<InputLabel id="demo-simple-select-label">Report Font </InputLabel>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																label="Report Font"
															>
																<MenuItem value={1}>A</MenuItem>
																<MenuItem value={2}>B</MenuItem>
																<MenuItem value={3}>C</MenuItem>
															</Select>
														</FormControl>
													</div>

													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="Report Export output only PDF"
															labelPlacement="Right"
														/>
													</div>
													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="Show Company Logo"
															labelPlacement="Right"
														/>
													</div>
												</Row>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="7">
											<Accordion.Header>ESS</Accordion.Header>
											<Accordion.Body>
												<Row>
													<div className='inputTextFiled '>
														<FormControlLabel
															value="Active"
															control={<Checkbox />}
															label="Refresh & Run Monthly Process"
															labelPlacement="Right"
														/>
													</div>
													<h6>Special Function Configuration</h6>
													<hr />
													<div className='inputTextFiled1 '>
														<FormControlLabel className='spacialFunc shadow-sm'
															value="Active"
															control={<Checkbox />}
															label="Office work in"
															labelPlacement="Right"
														/>
														<FormControlLabel className='spacialFunc shadow-sm'
															value="Active"
															control={<Checkbox />}
															label="Short Leave IN"
															labelPlacement="Right"
														/>
														<FormControlLabel className='spacialFunc shadow-sm'
															value="Active"
															control={<Checkbox />}
															label="Break End"
															labelPlacement="Right"
														/>
														<FormControlLabel className='spacialFunc shadow-sm'
															value="Active"
															control={<Checkbox />}
															label="Overtime IN"
															labelPlacement="Right"
														/>
														<FormControlLabel className='spacialFunc shadow-sm'
															value="Active"
															control={<Checkbox />}
															label="Short Leave Out"
															labelPlacement="Right"
														/>
													</div>
												</Row>
											</Accordion.Body>
										</Accordion.Item>


										<Accordion.Item eventKey="8">
											<Accordion.Header>SSO Configuration</Accordion.Header>
											<Accordion.Body>
												<Accordion defaultActiveKey="0">

													<Accordion.Item eventKey="0">
														<Accordion.Header>IDP Profile Details</Accordion.Header>
														<Accordion.Body className='p-3'>
															<div className='inputTextFiled'>
																<FormControl size='small' fullWidth>
																	<InputLabel id="demo-simple-select-label">Identity Provider</InputLabel>
																	<Select
																		labelId="demo-simple-select-label"
																		id="demo-simple-select"
																		label="Identity Provider"
																	>
																		<MenuItem value={1}>0</MenuItem>
																		<MenuItem value={2}>1</MenuItem>
																		<MenuItem value={3}>2</MenuItem>
																	</Select>
																</FormControl>
															</div>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Custom IDP" size='small' variant="outlined" />
															</div>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Description" size='small' variant="outlined" />
															</div>
															<button className='btn btn-outline-success'>Save</button>
														</Accordion.Body>
													</Accordion.Item>

													<Accordion.Item eventKey="1">
														<Accordion.Header>Connection</Accordion.Header>
														<Accordion.Body className='p-3'>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Connection" size='small' variant="outlined" />
															</div>
															<button className='btn btn-outline-success'>Save</button>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="2">
														<Accordion.Header>Attribute Mapping</Accordion.Header>
														<Accordion.Body className='p-3'>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Connection" size='small' variant="outlined" />
															</div>
															<button className='btn btn-outline-success'>Save</button>
														</Accordion.Body>
													</Accordion.Item>


												</Accordion>


											</Accordion.Body>
										</Accordion.Item>

									</Accordion>
								</div>
							</div>

						</Col>

					</Row>
				</Container>
			</div>
		</>
	);
}
export default GlobalPolicy;
