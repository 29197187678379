import '../css/HrStyle.css';
import '../../../Media.css';
import { useState, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplayIcon from '@mui/icons-material/Replay';
import { Link } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ModeIcon from '@mui/icons-material/Mode';
import UserListFor_Admin from './UserListFor_Admin';
function IdentificationServer() {


	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>Identification Server Configuration</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CalendarMonthIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'><Link to='/hr-dashboard'><ReplayIcon /></Link></button>
					</div>
				</div>
				<div className='clear'></div>
				<Container fluid className='animate__animated animate__fadeInDown'>
					<Row>
						<Col className='col-md-8 col-sm-12 col-12'>
							<div className='mainBoxTabs'>
								<div className='accordionBgClr'>
									<Accordion defaultActiveKey="0">

										<Accordion.Item eventKey="0">
											<Accordion.Header>Server</Accordion.Header>
											<Accordion.Body className='p-3'>

												<div className='inputTextFiled'>
													<TextField autoComplete="off" fullWidth required id="outlined-basic" label="ID" size='small' variant="outlined" />
												</div>
												<div className='inputTextFiled'>
													<TextField autoComplete="off" fullWidth required id="outlined-basic" label="MAC Address" size='small' variant="outlined" />
												</div>
												<div className='inputTextFiled'>
													<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Server Address" size='small' variant="outlined" />
												</div>

												<div className='inputTextFiled'>
													<FormControlLabel className='spacialFunc shadow-sm'
														value="Active"
														control={<Checkbox />}
														label="Support global Identification"
														labelPlacement="Right"
													/>
													<FormControlLabel className='spacialFunc shadow-sm'
														value="Active"
														control={<Checkbox />}
														label="Enable secure communication"
														labelPlacement="Right"
													/>
												</div>
												<button className='btn btn-outline-success'>Save</button>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="1">
											<Accordion.Header>Biometric group based Identification</Accordion.Header>
											<Accordion.Body className='p-3'>
												<div className='inputTextFiled'>
													<FormControlLabel className='spacialFunc shadow-sm'
														value="Active"
														control={<Checkbox />}
														label="Enable"
														labelPlacement="Right"
													/>
													<FormControlLabel className='spacialFunc shadow-sm'
														value="Active"
														control={<Checkbox />}
														label="Extended Search"
														labelPlacement="Right"
													/>
												</div>
												<div className='inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Extend search in</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Extend search in"
														>
															<MenuItem value={1}>All</MenuItem>
															<MenuItem value={2}>1</MenuItem>
															<MenuItem value={3}>2</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div className='inputTextFiled'>
													<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Matching Theshold (Palm) %" size='small' variant="outlined" />
												</div>
												<div className='inputTextFiled'>
													<FormControl size='small' fullWidth>
														<InputLabel id="demo-simple-select-label">Matching Theshold (FB)</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label="Matching Theshold (FB)"
														>
															<MenuItem value={1}>Normal</MenuItem>
															<MenuItem value={2}>High</MenuItem>
															<MenuItem value={3}>No</MenuItem>
														</Select>
													</FormControl>
												</div>


												<button className='btn btn-outline-success'>Save</button>
											</Accordion.Body>
										</Accordion.Item>

									</Accordion>
								</div>
							</div>
						</Col>
						<Col className='col-md-4 col-sm-12 col-12'>
							<UserListFor_Admin />
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}
export default IdentificationServer;
