import '../css/HrStyle.css';
import '../../../Media.css';
import { useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplayIcon from '@mui/icons-material/Replay';
import { Link } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import UserListFor_Admin from './UserListFor_Admin';
import Accordion from 'react-bootstrap/Accordion';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
function System_account() {
	const [tabData, settabData] = useState({
		systemData: true,
		rolesCon: false,
	})
	function tabSMenu(act) {
		if (act == 1) {
			settabData((prv) => {
				return {
					...prv,
					systemData: true,
					rolesCon: false,
				}
			})
		} else {
			settabData((prv) => {
				return {
					...prv,
					systemData: false,
				}
			})
		}
		if (act == 2) {
			settabData((prv) => {
				return {
					...prv,
					systemData: false,
					rolesCon: true,
				}
			})
		} else {
			settabData((prv) => {
				return {
					...prv,
					rolesCon: false,
				}
			})
		}
	}
	return (
		<>
			<div className='white_bgs'>
				<div className='row g-0 quickLink'>
					<div className='col d-inline-flex align-items-center px-1'>System Account</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CalendarMonthIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'><Link to='/hr-dashboard'><ReplayIcon /></Link></button>
					</div>
				</div>
				<div className='clear'></div>
				<Container fluid className='animate__animated animate__fadeInDown'>
					<Row>
						<Col className='col-md-8 col-sm-8 col-12'>
							<div className='mainBoxTabs'>
								<RadioGroup>
									<div className='rowTabs row g-0'>
										<div className='col-auto mt-2' onClick={() => { tabSMenu(1) }}>
											<FormControlLabel className='pe-3' checked={tabData.systemData ? 'true' : ''} value="1"
												control={<Radio />} label="System Accounts" />
										</div>
										<div className='col-auto mt-2' onClick={() => { tabSMenu(2) }}>
											<FormControlLabel className='pe-3' checked={tabData.rolesCon ? 'true' : ''} value="2"
												control={<Radio />} label="Roles and Rights Configuration" />
										</div>
									</div>
								</RadioGroup>

								{tabData.systemData &&
									<div className='accordionBgClr animate__animated animate__zoomIn inputTextFiled'>
										<form>
											<Accordion defaultActiveKey="0">
												<Accordion.Item eventKey="0">
													<Accordion.Header>Accounts</Accordion.Header>
													<Accordion.Body>

														<div className='inputTextFiled'>
															<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Login ID" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Name" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Role</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Role"
																>
																	<MenuItem value={1}>System Administrator</MenuItem>
																	<MenuItem value={2}>System Engineer</MenuItem>
																	<MenuItem value={3}>System Operator</MenuItem>
																</Select>
															</FormControl>
														</div>
														<div className='inputTextFiled'>
															<FormControlLabel
																value="Active"
																control={<Checkbox />}
																label="Active"
																labelPlacement="Right"
															/>
														</div>

													</Accordion.Body>
												</Accordion.Item>
												<Accordion.Item eventKey="1">
													<Accordion.Header>Optinal</Accordion.Header>
													<Accordion.Body>

														<div className='inputTextFiled'>
															<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Linked ID" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Name" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Preferred Language</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Preferred Language"
																>
																	<MenuItem value={1}>English</MenuItem>
																	<MenuItem value={2}>Hindi</MenuItem>
																	<MenuItem value={3}>Punjabi</MenuItem>
																</Select>
															</FormControl>
														</div>
														<div className='inputTextFiled'>
															<FormControlLabel
																value="Enable API Access"
																control={<Checkbox />}
																label="Enable API Access"
																labelPlacement="Right"
															/>
														</div>
														<div className='inputTextFiled'>
															<FormControlLabel
																value="Report Export Output In PDF Only"
																control={<Checkbox />}
																label="Report Export Output In PDF Only"
																labelPlacement="Right"
															/>
														</div>
														<div className='inputTextFiled'>
															<FormControlLabel
																value="Login Via Active Directory"
																control={<Checkbox />}
																label="Login Via Active Directory"
																labelPlacement="Right"
															/>
														</div>
														<div className='inputTextFiled'>
															<TextField autoComplete="off" disabled fullWidth required id="outlined-basic" label="Username" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<TextField autoComplete="off" disabled fullWidth required id="outlined-basic" label="Domain" size='small' variant="outlined" />
														</div>
														<div className='inputTextFiled'>
															<button disabled className='btn btn-secondary btn-sm'>Default Domain</button>
														</div>
														<div className='setPages'>Set Launch Page</div>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Module</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Module"
																>
																	<MenuItem value={1}>A</MenuItem>
																	<MenuItem value={2}>B</MenuItem>
																	<MenuItem value={3}>C</MenuItem>
																</Select>
															</FormControl>
														</div>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Page</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Page"
																>
																	<MenuItem value={1}>1</MenuItem>
																	<MenuItem value={2}>2</MenuItem>
																	<MenuItem value={3}>3</MenuItem>
																</Select>
															</FormControl>
														</div>
													</Accordion.Body>
												</Accordion.Item>


												<Accordion.Item eventKey="2">
													<Accordion.Header>Group-Wise Rights</Accordion.Header>
													<Accordion.Body>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Select Users</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Select Users"
																>
																	<MenuItem value={1}>All</MenuItem>
																</Select>
															</FormControl>
														</div>
													</Accordion.Body>
												</Accordion.Item>

												<Accordion.Item eventKey="3">
													<Accordion.Header>Device Rights</Accordion.Header>
													<Accordion.Body>
														<div className='inputTextFiled'>
															<FormControl size='small' fullWidth>
																<InputLabel id="demo-simple-select-label">Device Filter</InputLabel>
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	label="Device Filter"
																>
																	<MenuItem value={1}>All</MenuItem>
																	<MenuItem value={2}>Android</MenuItem>
																</Select>
															</FormControl>
														</div>
													</Accordion.Body>
												</Accordion.Item>

											</Accordion>
										</form>
									</div>
								}
								{tabData.rolesCon &&
									<div className='accordionBgClr animate__animated animate__zoomIn'>
										<form>
											<div className='headerS'>
												<Container fluid>
													<Row>
														<Col className='col-md-4 col-sm-4 col-12'>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Role" size='small' variant="outlined" />
															</div>
														</Col>
														<Col className='col-md-4 col-sm-4 col-12'>
															<div className='inputTextFiled'>
																<TextField autoComplete="off" fullWidth required id="outlined-basic" label="Copy right As per" size='small' variant="outlined" />
															</div>
														</Col>
														<Col className='col-md-4 col-sm-4 col-12'>
															<div className='inputTextFiled'>
																<FormControl size='small' fullWidth>
																	<InputLabel id="demo-simple-select-label">Module</InputLabel>
																	<Select
																		labelId="demo-simple-select-label"
																		id="demo-simple-select"
																		label="Module"
																	>
																		<MenuItem value={1}>Admin</MenuItem>
																		<MenuItem value={2}>Time and Attendance</MenuItem>
																		<MenuItem value={3}>Leave Management</MenuItem>
																		<MenuItem value={4}>User</MenuItem>
																		<MenuItem value={5}>Device</MenuItem>
																		<MenuItem value={6}>Shift and Unshift</MenuItem>
																	</Select>
																</FormControl>
															</div>
														</Col>
													</Row>

													<div className='table-responsive'>
														<table className='table table-bordered table-hover tblsRols'>
															<thead>
																<tr className='alignTopSect success' valign="middle">
																	<th>Menu</th>
																	<th>Parent Menu</th>
																	<th>
																		<FormControlLabel
																			value="View"
																			control={<Checkbox />}
																			label="View"
																			labelPlacement="Right"
																		/>
																	</th>
																	<th>
																		<FormControlLabel
																			value="Add"
																			control={<Checkbox />}
																			label="Add"
																			labelPlacement="Right"
																		/>
																	</th>
																	<th>
																		<FormControlLabel
																			value="Edit"
																			control={<Checkbox />}
																			label="Edit"
																			labelPlacement="Right"
																		/>
																	</th>
																	<th>
																		<FormControlLabel
																			value="Delete"
																			control={<Checkbox />}
																			label="Delete"
																			labelPlacement="Right"
																		/>
																	</th>
																	<th>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			label="Print"
																			labelPlacement="Right"
																		/>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr className='hidePaddin' valign="middle">
																	<td>Dashboard</td>
																	<td></td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td></td>
																	<td></td>
																	<td></td>
																	<td></td>
																</tr>
																<tr className='hidePaddin' valign="middle">
																	<td>System Accordion</td>
																	<td></td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>

																	</td>
																	<td></td>
																</tr>
																<tr className='hidePaddin' valign="middle">
																	<td>Gloabl Policy</td>
																	<td>System Configuration</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>
																	</td>
																	<td>
																		<FormControlLabel
																			value="Print"
																			control={<Checkbox />}
																			labelPlacement="Right"
																		/>

																	</td>
																	<td></td>
																</tr>
															</tbody>
														</table>
													</div>
												</Container>
											</div>
										</form>
									</div>
								}

							</div>

						</Col>
						<Col className='col-md-4 col-sm-4 col-12'>
							<UserListFor_Admin />
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}
export default System_account;
